<template>
  <div
    id="user-profile"
    class="pb-4"
  >

    <!-- strat header -->
    <b-card
      class="profile-header mb-2"
      img-src="https://pixinvent.com/demo/vuexy-vuejs-laravel-admin-template/demo-2/images/profile/user-uploads/timeline.jpg"
      img-top
      body-class="p-0"
    >

      <!-- profile picture -->
      <div class="position-relative mt-2">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img
              :src="activeLiff && activeLiff.pictureUrl"
              rounded
              fluid
            />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-1 pt-5 mt-2">
            <h2 class="font-weight-bold">
              {{ activeLiff && activeLiff.displayName }}
            </h2>
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile navbar -->
      <div class="px-2 pt-3 mx-75">

        <b-tabs v-model="statusIndex">
          <b-tab
            active
            title="待預約"
          />
          <b-tab title="已預約" />
          <b-tab title="已過期" />
        </b-tabs>

      </div>
      <!--/ profile navbar -->
    </b-card>
    <!-- end header -->

    <router-view :status-index="statusIndex" />

  </div>

</template>

<script>
import {
  BCard, BImg, BTabs, BTab,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
// import store from '@/store'

export default {
  components: {
    BCard,
    BImg,
    BTabs,
    BTab,
  },
  setup() {
    // LINE LIFF 技術
    // const activeLiff = computed(() => store.state['app-line-liff'].profileInfo)
    const activeLiff = computed(() => { })
    const statusIndex = ref(0)

    return {
      activeLiff,
      statusIndex,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
.card-img-top {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: 100% 10%;
  opacity: 0.6;
}
</style>
